import React from 'react';
import styled from 'styled-components';
import { FaAndroid, FaApple, FaGlobe } from 'react-icons/fa'; // Import the globe icon for website
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../Context';
import { FaInstagram, FaFacebook } from 'react-icons/fa';

const CardContainer = styled.div`
    background-color: #f0f9ff; /* Changed to a lighter blue background color */
    padding: 20px;
    border-radius: 20px; /* Rounded corners */
    margin-bottom: 10px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.01);
    text-align: center;
    font-family: 'Roboto', sans-serif;
    max-width: 700px; /* Set a smooth card width */
    margin: 10px auto; /* Center the card on the page */
`;

const ServiceColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Space between icon, title, and description */
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    margin-bottom: 10px;
`;

const ServiceIcon = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 10px; /* Rounded icon */
`;

const ServiceTitle = styled.h3`
    font-size: 24px;
    margin: 0;
    font-family: 'Poppins', sans-serif; /* Changed font to Poppins */
`;

const Description = styled.p`
    font-size: 16px;
    color: #555;
    margin: 0;
    max-width: 700px; /* Limit description width */
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 15px;
`;

const DownloadButton = styled.a`
    -webkit-tap-highlight-color: transparent;
    background-color: ${props => props.bgColor || '#4CAF50'};
    color: white;
    padding: 10px 15px;
    border-radius: 10px; /* Slightly rounded corners for buttons */
    text-decoration: none;
    font-size: 14px;
    display: flex;
    align-items: center; /* Align icon and text vertically */
    gap: 8px; /* Space between icon and text */
    transition: background-color 0.3s;
    margin-top: 10px;

    &:hover {
        background-color: ${props => props.hoverColor || '#45a049'};
    }

    svg {
        font-size: 18px; /* Adjust icon size */
    }
`;

const WebsiteButton = styled.a`
    -webkit-tap-highlight-color: transparent;
    background-color: #d9d9d9; /* Beautiful grey color */
    color: #333; /* Dark text color for contrast */
    padding: 10px 15px;
    border-radius: 10px; /* Slightly rounded corners for buttons */
    text-decoration: none;
    font-size: 14px;
    display: flex;
    align-items: center; /* Align icon and text vertically */
    gap: 8px; /* Space between icon and text */
    transition: background-color 0.3s;
    cursor: pointer;
    margin-top: 10px;

    &:hover {
        background-color: #bfbfbf; /* Darker grey for hover */
    }

    svg {
        font-size: 18px; /* Adjust icon size */
    }
`;


const SocialMediaButton = styled.a`
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    background-color: ${props => props.bgColor};
    color: white;
    padding: 10px 15px;
    border-radius: 10px; /* Rounded corners for social media buttons */
    text-decoration: none;
    font-size: 14px;
    transition: background-color 0.3s;
    margin-top: 10px;

    &:hover {
        background-color: ${props => props.hoverColor};
    }

    svg {
        margin-right: 10px;
    }
`;

const BigServiceCard = ({ service }) => {
    const { t, i18n } = useTranslation();
    
    const { 
        language, setLanguage,
        languageDirection, setLanguageDirection,
        
    } = useAppContext();
    
    return (
        <CardContainer>
            <ServiceColumn>
                <ServiceIcon src={service.icon} alt={`${service.title} icon`} />
                <ServiceTitle style={{ color: service.titleColor }}>{service.title}</ServiceTitle>
                <Description dir={languageDirection}>{t(service.description)}</Description>
            </ServiceColumn>
            <ButtonContainer>
                <WebsiteButton 
                    href={`${service.websiteLink}?lang=${language}`}  // Add the link for the website
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    <FaGlobe /> {t("Visit Website")}
                </WebsiteButton>
                <SocialMediaButton 
                    href={`${service.instagramLink}?lang=${language}`}
                    bgColor="#E4405F" 
                    hoverColor="#C13584"
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    <FaInstagram /> {t("Instagram")}
                </SocialMediaButton>
                <SocialMediaButton 
                    href={`${service.facebookLink}?lang=${language}`}
                    bgColor="#3b5998" 
                    hoverColor="#2d4373"
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    <FaFacebook /> {t("Facebook")}
                </SocialMediaButton>
            </ButtonContainer>
        </CardContainer>
    );
};

export default BigServiceCard;
